import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as contentblockActions from '../actions/news-category.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NewsCategoryService } from '../../services';

@Injectable()
export class NewsCategoryEffects {
  constructor(private actions$: Actions, private PageService: NewsCategoryService) {}

  LoadBugs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentblockActions.LoadCategories),
      switchMap(() =>
        this.PageService.getNewsCategories().pipe(
          map(categories => contentblockActions.LoadCategoriesSuccess({ categories })),
          catchError(error => of(contentblockActions.LoadCategoriesFail({ error })))
        )
      )
    );
  });
}
