import { createReducer, on } from '@ngrx/store';
import { Job } from '../../models';
import * as pageActions from '../actions/jobs.action';
import * as utility from '../../../utility';

export interface JobsState {
  entities: { [id: number]: Job[] };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: JobsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const JobsReducer = createReducer(
  initialState,
  on(pageActions.LoadJobs, (state: JobsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadJobsSuccess, (state: JobsState, { offers }) => {
    const entities = utility.ToEntities(offers, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadJobsFail, (state: JobsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getJobsEntities = (state: JobsState) => state.entities;
export const getJobsLoading = (state: JobsState) => state.loading;
export const getJobsLoaded = (state: JobsState) => state.loaded;
