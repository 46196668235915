import { createReducer, on } from '@ngrx/store';
import { NewsCategory } from '../../models';
import * as pageActions from '../actions/news-category.action';
import * as utility from '../../../utility';

export interface NewsCategoriesState {
  entities: { [id: number]: NewsCategory };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: NewsCategoriesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const NewsCategoryReducer = createReducer(
  initialState,
  on(pageActions.LoadCategories, (state: NewsCategoriesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadCategoriesSuccess, (state: NewsCategoriesState, { categories }) => {
    const entities = utility.ToEntities(categories, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadCategoriesFail, (state: NewsCategoriesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getNewsCategoriesEntities = (state: NewsCategoriesState) => state.entities;
export const getNewsCategoriesLoading = (state: NewsCategoriesState) => state.loading;
export const getNewsCategoriesLoaded = (state: NewsCategoriesState) => state.loaded;
