import { createReducer, on } from '@ngrx/store';
import { ContentPage } from '../../models';
import * as pageActions from '../actions/content-pages.action';
import * as utility from '../../../utility';

export interface ContentPagesState {
  entities: { [id: number]: ContentPage };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ContentPagesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const contentPageReducer = createReducer(
  initialState,
  on(pageActions.LoadContentPages, (state: ContentPagesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadContentPagesSuccess, (state: ContentPagesState, { contentPages }) => {
    const entities = utility.ToEntities(contentPages, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadContentPagesFail, (state: ContentPagesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getContentPagesEntities = (state: ContentPagesState) => state.entities;
export const getContentPagesLoading = (state: ContentPagesState) => state.loading;
export const getContentPagesLoaded = (state: ContentPagesState) => state.loaded;
