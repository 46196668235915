import * as fromPages from '../reducers/content-pages.reducer';
import * as fromNews from '../reducers/news.reducer';
import * as fromOffices from '../reducers/offices.reducer';
import * as fromCategory from '../reducers/news-category.reducer';
import * as fromJobs from '../reducers/jobs.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content';

export interface ContentState {
  contentPages: fromPages.ContentPagesState;
  news: fromNews.NewsState;
  categories: fromCategory.NewsCategoriesState;
  offices: fromOffices.OfficesState;
  offers: fromJobs.JobsState;
}

export const reducers: ActionReducerMap<ContentState> = {
  contentPages: fromPages.contentPageReducer,
  news: fromNews.NewsReducer,
  categories: fromCategory.NewsCategoryReducer,
  offices: fromOffices.OfficeReducer,
  offers: fromJobs.JobsReducer,
};

export const getContentState = createFeatureSelector<ContentState>('content');

export const metaReducers: MetaReducer<ContentState>[] = !environment.production ? [] : [];
