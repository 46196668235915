import { createSelector } from '@ngrx/store';
import { Office } from '../../../people/models';

import * as fromFeature from '../reducers';
import * as fromOffices from '../reducers/offices.reducer';

export const getOfficeState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.offices);

export const getOfficeEntities = createSelector(getOfficeState, fromOffices.getOfficesEntities);

export const getAllOffices = createSelector(getOfficeEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedOffices = createSelector(getAllOffices, (offices: Office[]) => {
  return offices.sort((a, b) => a.order - b.order);
});

export const getOfficesLoaded = createSelector(getOfficeState, fromOffices.getOfficesLoaded);
export const getOfficesLoading = createSelector(getOfficeState, fromOffices.getOfficesLoading);
