import { createReducer, on } from '@ngrx/store';
import { News } from '../../models';
import * as partnerActions from '../actions/news.action';
import * as utility from '../../../utility';
import { Project, ProjectImage } from '../../../projects/models';

export interface NewsState {
  entities: { [id: number]: News };
  relatedProjects: Project[];
  relatedNews: News[];
  length: number;
  loadMore: boolean;
  loaded: boolean;
  loading: boolean;
  gallery: ProjectImage[];
  error?: any;
  loadedProjects: boolean;
  loadingProjects: boolean;
  loadedRelated: boolean;
  loadingRelated: boolean;
}

export const initialState: NewsState = {
  entities: {},
  relatedProjects: [],
  relatedNews: [],
  gallery: [],
  length: 0,
  loadMore: true,
  loaded: false,
  loading: false,
  loadedProjects: false,
  loadingProjects: false,
  loadedRelated: false,
  loadingRelated: false,
};

export const NewsReducer = createReducer(
  initialState,
  on(partnerActions.LoadNews, (state: NewsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(partnerActions.LoadNewsSuccess, (state: NewsState, { news }) => {
    const entities = utility.ToEntities(news, 'id', state.entities);
    return {
      ...state,
      entities,
      length: state.length + news.length,
      loadMore: news.length > 0,
      loading: false,
      loaded: true,
    };
  }),
  on(partnerActions.LoadNewsFail, (state: NewsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(partnerActions.ClearNews, (state: NewsState) => {
    return {
      ...state,
      entities: {},
      relatedProjects: [],
      length: 0,
      loadMore: true,
      loading: false,
      loaded: false,
      loadedProjects: false,
      loadingProjects: false,
      loadingRelated: false,
      loadedRelated: false,
      relatedNews: [],
    };
  }),
  on(partnerActions.LoadRelatedProjectsSuccess, (state: NewsState, { projects }) => {
    return {
      ...state,
      loadedProjects: true,
      loadingProjects: false,
      relatedProjects: [...projects],
    };
  }),
  on(partnerActions.LoadRelatedProjects, (state: NewsState) => {
    return {
      ...state,
      loadedProjects: false,
      loadingProjects: true,
    };
  }),
  on(partnerActions.LoadRelatedProjectsFail, (state: NewsState, { error }) => {
    return {
      ...state,
      loadedProjects: false,
      loadingProjects: false,
      error,
    };
  }),
  on(partnerActions.LoadRelatedNewsSuccess, (state: NewsState, { news }) => {
    return {
      ...state,
      loadedRelated: true,
      loadingRelated: false,
      relatedNews: [...news],
    };
  }),
  on(partnerActions.LoadRelatedNews, (state: NewsState) => {
    return {
      ...state,
      loadedRelated: false,
      loadingRelated: true,
    };
  }),
  on(partnerActions.LoadRelatedNewsFail, (state: NewsState, { error }) => {
    return {
      ...state,
      loadedRelated: false,
      loadingRelated: false,
      error,
    };
  })
);

export const getNewsEntities = (state: NewsState) => state.entities;
export const getNewsLoading = (state: NewsState) => state.loading;
export const getNewsLoadMore = (state: NewsState) => state.loadMore;
export const getNewsLoaded = (state: NewsState) => state.loaded;
export const getNewsLength = (state: NewsState) => state.length;
export const getNewsError = (state: NewsState) => state.error;
export const getNewsRelatedProjects = (state: NewsState) => state.relatedProjects;
export const getNewsLoadingProjects = (state: NewsState) => state.loadingProjects;
export const getNewsLoadedProjects = (state: NewsState) => state.loadedProjects;

export const getRelatedNews = (state: NewsState) => state.relatedNews;
export const getRelatedNewsLoading = (state: NewsState) => state.loadingRelated;
export const getRelatedNewsLoaded = (state: NewsState) => state.loadedRelated;
