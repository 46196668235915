import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as jobActions from '../actions/jobs.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { RecruiteeService } from '../../services';
import { Job } from '../../models';

@Injectable()
export class JobsEffects {
  constructor(private actions$: Actions, private PageService: RecruiteeService) {}

  LoadJobs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(jobActions.LoadJobs),
      switchMap(() => this.PageService.getJobs()),
      map(offers => {
        return jobActions.LoadJobsSuccess(offers);
      }),
      catchError(error => of(jobActions.LoadJobsFail({ error })))
    );
  });
}
