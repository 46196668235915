import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../actions/offices.action';
import * as utility from '../../../utility';
import { Office } from '../../../people/models';

export interface OfficesState {
  entities: { [id: number]: Office };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: OfficesState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const OfficeReducer = createReducer(
  initialState,
  on(pageActions.LoadOffices, (state: OfficesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadOfficesSuccess, (state: OfficesState, { Offices }) => {
    const entities = utility.ToEntities(Offices, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadOfficesFail, (state: OfficesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getOfficesEntities = (state: OfficesState) => state.entities;
export const getOfficesLoading = (state: OfficesState) => state.loading;
export const getOfficesLoaded = (state: OfficesState) => state.loaded;
