import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/news.action';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { NewsService } from '../../services';
import { ContentState } from '../reducers';
import { Store } from '@ngrx/store';
import { getNewsLength } from '../selectors';
import { getRouterState } from '../../../store';

@Injectable()
export class NewsEffects {
  constructor(private actions$: Actions, private newsService: NewsService, private store: Store<ContentState>) {}

  LoadNews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadNews),
      switchMap(({ skip, top, category }) => this.newsService.getNews(top, skip, category)),
      map(news => actions.LoadNewsSuccess({ news })),
      catchError(error => of(actions.LoadNewsFail({ error })))
    );
  });

  LoadRelatedProjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadRelatedProjects),
      switchMap(({ newsId }) =>
        this.newsService.getRelatedProjects(newsId).pipe(
          map(projects => actions.LoadRelatedProjectsSuccess({ projects, newsId })),
          catchError(error => of(actions.LoadRelatedProjectsFail({ error })))
        )
      )
    );
  });

  LoadRelatedNews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadRelatedNews),
      switchMap(({ newsId }) =>
        this.newsService.getRelatedNews(newsId).pipe(
          map(news => actions.LoadRelatedNewsSuccess({ news, newsId })),
          catchError(error => of(actions.LoadRelatedNewsFail({ error })))
        )
      )
    );
  });

  LoadNewsItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadNewsItem),
      switchMap(({ id }) => this.newsService.getNewsItem(id)),
      map(news => actions.LoadNewsSuccess({ news: [news] })),
      catchError(error => of(actions.LoadNewsFail({ error })))
    );
  });

  LoadMoreNews$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadMoreNews),
      withLatestFrom(this.store.select(getNewsLength), this.store.select(getRouterState)),
      switchMap(([{}, length, router]) =>
        this.newsService.getNews(null, length, router?.state?.queryParams['category']).pipe(
          map(news => actions.LoadNewsSuccess({ news: news })),
          catchError(error => of(actions.LoadNewsFail({ error })))
        )
      )
    );
  });
}
