import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromNewsCategory from '../reducers/news-category.reducer';
import { NewsCategory } from '../../models';
import * as fromRoot from '../../../store';

export const getNewsCategoryState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.categories);

export const getNewsCategoryEntities = createSelector(getNewsCategoryState, fromNewsCategory.getNewsCategoriesEntities);

export const getAllNewsCategories = createSelector(getNewsCategoryEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getNewsCategoryLoaded = createSelector(getNewsCategoryState, fromNewsCategory.getNewsCategoriesLoaded);

export const getNewsCategoryLoading = createSelector(getNewsCategoryState, fromNewsCategory.getNewsCategoriesLoading);
