import { ContentPagesEffects } from './content-pages.effect';
import { NewsCategoryEffects } from './news-category.effect';
import { NewsEffects } from './news.effect';
import { OfficesEffects } from './offices.effect';
import { JobsEffects } from './jobs.effect';

export const effects = [ContentPagesEffects, NewsEffects, NewsCategoryEffects, OfficesEffects, JobsEffects];

export * from './content-pages.effect';
export * from './news.effect';
export * from './news-category.effect';
export * from './offices.effect';
export * from './jobs.effect';
