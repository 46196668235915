import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromJobs from '../reducers/jobs.reducer';
import * as fromRoot from '../../../store';

export const getJobsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.offers);

export const getJobsEntities = createSelector(getJobsState, fromJobs.getJobsEntities);

export const getAllJobs = createSelector(getJobsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getJobsLoaded = createSelector(getJobsState, fromJobs.getJobsLoaded);

export const getJobsLoading = createSelector(getJobsState, fromJobs.getJobsLoading);
