import { createAction, props } from '@ngrx/store';
import { Project } from '../../../projects/models';
import { News } from '../../models';

export const LoadNews = createAction('[Content] Load News', props<{ top: number; skip: number; category?: number }>());

export const LoadMoreNews = createAction('[Content] Load More News');
export const LoadNewsItem = createAction('[Content] Load News Item', props<{ id: number }>());

export const LoadNewsSuccess = createAction('[Content] Load News Success', props<{ news: News[] }>());
export const LoadNewsFail = createAction('[Content] Load News Fail', props<{ error: any }>());

export const ClearNews = createAction('[Content] Clear News');

export const LoadRelatedProjects = createAction('[News] Load Related Projects', props<{ newsId: number }>());
export const LoadRelatedProjectsSuccess = createAction(
  '[News] Load Related Projects Success',
  props<{ newsId: number; projects: Project[] }>()
);
export const LoadRelatedProjectsFail = createAction('[News] Load Related Projects Fail', props<{ error: any }>());

export const LoadRelatedNews = createAction('[News] Load Related News', props<{ newsId: number }>());
export const LoadRelatedNewsSuccess = createAction('[News] Load Related News Success', props<{ newsId: number; news: News[] }>());
export const LoadRelatedNewsFail = createAction('[News] Load Related News Fail', props<{ error: any }>());
