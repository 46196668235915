import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentPage } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ContentPagesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getPages() {
    return this.http.get<ContentPage[]>(`${this.BASE_URL}api/pages`);
  }
}
