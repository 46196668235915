import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { News } from '../models';
import { Project } from '../../projects/models';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getNews(top: number = 25, skip: number = 0, category?: number) {
    return this.http.get<News[]>(`${this.BASE_URL}api/news?top=${top}&skip=${skip}&category=${category || ''}`);
  }

  getRelatedProjects(newsId: number) {
    return this.http.get<Project[]>(`${this.BASE_URL}api/news/${newsId}/related-projects`);
  }

  getRelatedNews(newsId: number) {
    return this.http.get<News[]>(`${this.BASE_URL}api/news/${newsId}/related-news`);
  }

  getNewsItem(id: number) {
    return this.http.get<News>(`${this.BASE_URL}api/news/${id}`);
  }
}
