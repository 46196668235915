import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromNews from '../reducers/news.reducer';
import { News } from '../../models';
import * as fromRoot from '../../../store';

export const getNewsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.news);

export const getNewsEntities = createSelector(getNewsState, fromNews.getNewsEntities);

export const getAllNews = createSelector(getNewsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedNews = createSelector(getAllNews, (values: News[]) => {
  return values?.sort((a, b) => (new Date(a.updatedOn).getTime() < new Date(b.updatedOn).getTime() ? 1 : -1));
});

export const getNewsLoaded = createSelector(getNewsState, fromNews.getNewsLoaded);

export const getNewsLoading = createSelector(getNewsState, fromNews.getNewsLoading);
export const getNewsLoadMore = createSelector(getNewsState, fromNews.getNewsLoadMore);
export const getNewsLength = createSelector(getNewsState, fromNews.getNewsLength);
export const getNewsError = createSelector(getNewsState, fromNews.getNewsError);

export const getNewsProjectsLoading = createSelector(getNewsState, fromNews.getNewsLoadingProjects);
export const getNewsProjectsLoaded = createSelector(getNewsState, fromNews.getNewsLoadedProjects);
export const getNewsRelatedProjects = createSelector(getNewsState, fromNews.getNewsRelatedProjects);

export const getRelatedNewsLoading = createSelector(getNewsState, fromNews.getRelatedNewsLoading);
export const getRelatedNewsLoaded = createSelector(getNewsState, fromNews.getRelatedNewsLoaded);
export const getRelatedNews = createSelector(getNewsState, fromNews.getRelatedNews);

export const getSelectedNews = createSelector(
  getNewsEntities,
  fromRoot.getRouterState,
  (entities, router): News => {
    const item = router.state && entities[router.state.params?.newsId || router.state.data?.newsId];
    return item;
  }
);

export const getNewsBreadcrumbs = createSelector(getSelectedNews, (page: News) => {
  const breadcrumbs = [];

  if (page) {
    breadcrumbs.push({ title: 'Nieuws', routerLink: ['/', 'nieuws'] });
    breadcrumbs.push({ title: page.title, routerLink: page.routerLink });
  }

  return breadcrumbs;
  // return pages.filter(a => a.inMenu);
});
